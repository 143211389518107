import { render, staticRenderFns } from "./cityForm.vue?vue&type=template&id=5fbece38&"
import script from "./cityForm.vue?vue&type=script&lang=js&"
export * from "./cityForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports